import React from 'react'
import CareerLayout from '../../components/careerLayout'

export default function MarketingLead () {
  return (
    <CareerLayout title='Marketing Lead' location='Remote'>
      <h2>Overview</h2>
      <p>Trexity requires an enthusiastic brand builder ready to help us reach our marketing goals with innovative strategy and execution.</p>

      <p className='mb'>Our Marketing Lead will be responsible for promoting our brand to merchants, drivers and the community at large. Your duties will include developing and coordinating campaigns, maximizing demographic reach, and maintaining a strong brand image. </p>

      <h2>Responsibilities</h2>
      <ul className='trx-bullet mb'>
        <li>Supporting the VP of Marketing and Brand with planning and development of marketing strategies on multiple platforms to help Trexity grow and reach our defined goals</li>
        <li>Overseeing of social media accounts, including campaign work and daily updates</li>
        <li>Implementation of search engine optimization (SEO) best practices in our online media</li>
        <li>Researching trends and new marketing opportunities</li>
        <li>Communication coordination between the marketing team, internal staff in other departments and with merchants using our service</li>
        <li>Addressing problems and troubleshooting campaigns</li>
        <li>Analyzing customer feedback and campaign performance</li>
      </ul>

      <h2>Experience and skills</h2>
      <ul className='trx-bullet mb'>
        <li>4 to 6 years experience in the marketing world</li>
        <li>Enjoys working in a fast paced startup environment</li>
        <li>Excellent organizational and marketing planning capabilities</li>
        <li>Outstanding verbal and written communication skills</li>
        <li>Personable and able to work in a team environment</li>
        <li>Strong attention to detail</li>
        <li>Creative and innovative thinking, that is goal focused</li>
        <li>Good research and problem-solving skills</li>
        <li>Ability to work well under pressure</li>
        <li>Experience and comfort will all social media platforms as well as Mailchimp and Hubspot Marketing tools</li>
        <li>Adaptability...this job will require it!</li>
      </ul>

      <h2>Benefits</h2>
      <ul className='trx-bullet mb'>
        <li>Equal opportunity employer</li>
        <li>Full health benefits from day one</li>
        <li>3 weeks vacation</li>
        <li>Employee Share Option Plan</li>
        <li>Remote and in office work options</li>
      </ul>
    </CareerLayout>
  )
}
